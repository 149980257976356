<template>
  <v-card-text>
    <v-row v-if="!loading">
      <v-col cols="12" class="p-0">
        <v-card-title class="justify-center pt-0 text-break text-center">Ödev ve Sınavlarda Doğru Cevaplama Oranı
        </v-card-title>
        <apex-chart
            :options="chartOptions"
            :series="chartOptions.series"
            type="bar"
            height="400"
        ></apex-chart>
      </v-col>
      <v-col v-if="differenceChartOptions.series[0] && differenceChartOptions.series[0].data.length > 0" class="p-0" cols="12">
        <v-card-title class="justify-center py-0">Tutarlılık
          <v-tooltip left color="primary">
            <template v-slot:activator="{ on, attrs }">
              <v-icon class="ml-1" small v-on="on" v-bind="attrs">mdi-information</v-icon>
            </template>
            <span>
              Tutarlılık, ödev ve sınavların doğru cevaplama oranları arasındaki farkı gösterir.
            </span>
          </v-tooltip>
        </v-card-title>
        <apex-chart
            :options="differenceChartOptions"
            :series="differenceChartOptions.series"
            type="bar"
            height="400"
        ></apex-chart>
      </v-col>
    </v-row>
  </v-card-text>
</template>

<script>
import ApiService from '@/core/services/api.service.js'

export default {
  props: ['filters'],
  data() {
    return {
      item: {},
      loading: false,
      differenceChartOptions: {
        series: [],
        chart: {
          type: 'bar',
          height: 400
        },
        responsive: [
          {
            breakpoint: 200
          }
        ],
        plotOptions: {
          bar: {
            borderRadius: 4,
            dataLabels: {
              position: 'top',
            },
            colors: {
              ranges: [{
                from: -100,
                to: -1,
                color: '#F54E60'
              },
                {
                  from: 0,
                  to: 100,
                  color: '#0abb87'
                }]
            },
            columnWidth: '60%',
          }
        },
        dataLabels: {
          enabled: true,
          offsetY: -25,
          style: {
            fontSize: '12px',
            colors: ["#304758"]
          },
          formatter: function (val) {
            return val + "%";
          },
        },
        grid: {
          show: true,
          strokeDashArray: 4,
          xaxis: {
            lines: {
              show: true,
            },
          },
          yaxis: {
            lines: {
              show: true
            }
          },
          row: {
            colors: ['#f3f3f3', 'transparent'],
            opacity: 1
          },
        },
        yaxis: {
          max: 100,
          min: -100,
          tickAmount: 10,
          labels: {
            formatter: function (y) {
              return y.toFixed(0) + "%";
            }
          }
        },
        xaxis: {
          type: 'category',
          categories: [],
          labels: {
            rotate: -45
          }
        },
        annotations: {
          yaxis: [
            {
              y: 0,
              borderColor: '#000',
              label: {
                style: {
                  color: '#000',
                },
              }
            }
          ]
        }
      },
      chartOptions: {
        series: [
          {
            name: 'Ödev',
            data: []
          },
          {
            name: 'Sınav',
            data: []
          },
        ],
        chart: {
          type: 'bar',
          height: 400
        },
        responsive: [
          {
            breakpoint: 200
          }
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '75%',
            endingShape: 'rounded',
          },
        },
        dataLabels: {
          enabled: true,
          style: {
            fontSize: '11px',
            colors: ["#304758"]
          },
          formatter: function (val) {
            return val > 0 ? val.toFixed(1) : ''
          },
        },
        legend: {
          position: 'top'
        },
        grid: {
          show: true,
          row: {
            colors: ['#f3f3f3', 'transparent'],
            opacity: 1
          },
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          type: 'category',
          categories: [],
          labels: {
            rotate: -45
          }
        },
        yaxis: {
          max: 100,
          labels: {
            formatter: function (y) {
              return y + "%";
            }
          }
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + " %"
            }
          }
        }
      },
    }
  },
  mounted() {
    this.getDataFromApi()
  },
  methods: {
    getDataFromApi() {
      this.loading = true
      ApiService.setHeader()
      ApiService.post('api/Student/stability-report', this.filters)
          .then((data) => {
            this.item = data.data
            this.differenceChartOptions.xaxis.categories = this.item.filter(s => s.HomeworkPercentage > 0 && s.ExamPercentage > 0).map(s => s.LessonGroupName)
            this.differenceChartOptions.series = [{
              name: '',
              data: this.item.filter(s => s.HomeworkPercentage > 0 && s.ExamPercentage > 0).map(s => s.DifferencePercentage)
            }]

            var lessonGroupNames = this.item.map(s => s.LessonGroupName)
            for (var i = 0; i < lessonGroupNames.length; i++) {
              if (lessonGroupNames[i].split(' ').length > 1) {
                let lessonGroupName = lessonGroupNames[i].split(' ')
                this.chartOptions.xaxis.categories.push(lessonGroupName)
              } else {
                this.chartOptions.xaxis.categories.push([lessonGroupNames[i]])
              }
            }

            this.chartOptions.series[0].data = this.item.map(s => s.HomeworkPercentage)
            this.chartOptions.series[1].data = this.item.map(s => s.ExamPercentage)

            this.loading = false
          })
          .catch(({response}) => {
            ApiService.showError(response)
          })
    }
  }
}
</script>

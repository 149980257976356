<template>
  <div>
    <v-card-title>
      Sınav Performansı
      <v-spacer></v-spacer>
      <v-btn :to="{name: 'sinav-raporu'}" small text color="primary">
        Sınav Raporları Ekranı
        <v-icon small>mdi-arrow-right</v-icon>
      </v-btn>
    </v-card-title>
    <apex-chart
        v-if="!loading"
        :options="chartOptions"
        :series="series"
        type="bar"
        height="400"
    ></apex-chart>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service.js'

export default {
  props: ['filters'],
  data() {
    return {
      loading: false,
      series: [
        {
          name: 'D',
          type: 'column',
          data: []
        },
        {
          name: 'Y',
          type: 'column',
          data: []
        },
        {
          name: 'B',
          type: 'column',
          data: []
        },
        {
          name: 'NET',
          type: 'column',
          data: []
        },
        // {
        //   name: 'PUAN',
        //   type: 'line',
        //   data: []
        // }
      ],
      chartOptions: {
        chart: {
          type: 'bar',
          stacked: false,
        },
        colors: ['#00E396', '#F54E60', '#FFA800', '#03a9f4'],
        plotOptions: {
          bar: {
            borderRadius: 2,
            columnWidth: '80%',
            dataLabels: {
              position: 'top',
            },
          },
        },
        dataLabels: {
          enabled: true,
          position: 'top',
          style: {
            fontSize: '11px',
            colors: ["#304758"]
          },
          formatter: function (value, series) {
            if (series.seriesIndex == 0)
              return value + 'D'
            else if (series.seriesIndex == 1)
              return value + 'Y'
            else if (series.seriesIndex == 2)
              return value + 'B'
            else if (series.seriesIndex == 3)
              return value + 'N'
            else
              return value
          }
        },
        // stroke: {
        //   width: [2, 2, 2, 2, 4]
        // },
        responsive: [
          {
            breakpoint: 300
          }
        ],
        xaxis: {
          categories: [],
          type: 'category',
          labels: {
            rotate: -45
          },
        },
        yaxis: [
          {
            seriesName: 'D',
            max: 180,
            axisBorder: {
              show: true,
              color: '#008FFB'
            },
            labels: {
              style: {
                colors: '#008FFB',
              },
              formatter: function (value) {
                return value.toFixed(0)
              }
            },
            // title: {
            //   text: "D/Y/B",
            //   style: {
            //     color: '#008FFB',
            //   }
            // }
          },
          {
            seriesName: 'Y',
            max: 180,
            show: false,
            opposite: false,
          },
          {
            seriesName: 'B',
            max: 180,
            show: false,
            opposite: false,
          },
          {
            seriesName: 'NET',
            max: 180,
            show: false,
            opposite: false,
          },
          // {
          //   seriesName: 'PUAN',
          //   max: 700,
          //   opposite: true,
          //   axisTicks: {
          //     show: true,
          //   },
          //   axisBorder: {
          //     show: true,
          //     color: '#775DD0'
          //   },
          //   labels: {
          //     style: {
          //       colors: '#775DD0'
          //     },
          //     formatter: function (value) {
          //       return value.toFixed(0)
          //     }
          //   },
          //   title: {
          //     text: "Puan",
          //     style: {
          //       color: '#775DD0',
          //     }
          //   }
          // }
        ],
      },
    }
  },
  mounted() {
    // eslint-disable-next-line vue/no-mutating-props
    this.filters.IsLimited = true
    this.getDataFromApi()
  },
  methods: {
    getDataFromApi() {
      this.loading = true
      ApiService.setHeader()
      ApiService.post('api/Student/exam-report', this.filters)
          .then(({data}) => {
            this.chartOptions.xaxis.categories = data.map(s => s.ExamName.replace('2023-2024', '').replace('2023-24', '')
                .replace('2024-2025', '').replace('2024-25', '').replace('2025-2026', '').replace('2025-26', '')
                .replace('İSTEM', '').replace('FENTEK', '').replace('ASFA', '').trim())

            this.chartOptions.xaxis.categories = this.chartOptions.xaxis.categories.map(s => s.length > 20 ? s.substring(0, 20) + '...' : s)

            this.series[0].data = data.map(s => s.CorrectCount)
            this.series[1].data = data.map(s => s.WrongCount)
            this.series[2].data = data.map(s => s.EmptyCount)
            this.series[3].data = data.map(s => s.Net)
            // this.series[4].data = data.map(s => s.Point)

            this.chartOptions.yaxis.forEach((y, i) => {
              y.seriesName = this.series[i].name

              if (y.seriesName == 'PUAN')
                y.max = Math.max(...this.series[4].data) > 500 ? Math.ceil(Math.max(...this.series[4].data) / 100) * 100 : 500
              else
                y.max = Math.max(...this.series[0].data) > 100 ? Math.ceil(Math.max(...this.series[0].data) / 10) * 10 : 100
            })

            this.loading = false
          })
          .catch(({response}) => {
            ApiService.showError(response)
            this.loading = false
          })
    },

    // changeFilter(item) {
    //   // eslint-disable-next-line vue/no-mutating-props
    //   this.filters.IsLimited = item === 'SON 5 SINAV'
    //   this.getDataFromApi()
    // }
  }
}
</script>

<template>
  <v-card-text>
    <v-card-title class="justify-center pb-0">Görüşmeler</v-card-title>
    <apex-chart
        v-if="!loading && series.length > 0"
        :options="chartOptions"
        :series="series"
        type="bar"
        height="300"
    ></apex-chart>
    <v-alert v-else type="info" text class="m-5">
      Seçilen tarihler aralığında görüşme bulunamadı.
    </v-alert>
  </v-card-text>
</template>

<script>
import ApiService from '@/core/services/api.service.js'
import moment from 'moment'

export default {
  props: ['filters'],
  data() {
    return {
      loading: false,
      localeMonths: [
        {value: '01', text: 'Ocak '},
        {value: '02', text: 'Şubat '},
        {value: '03', text: 'Mart '},
        {value: '04', text: 'Nisan '},
        {value: '05', text: 'Mayıs '},
        {value: '06', text: 'Haziran '},
        {value: '07', text: 'Temmuz '},
        {value: '08', text: 'Ağustos '},
        {value: '09', text: 'Eylül '},
        {value: '10', text: 'Ekim '},
        {value: '11', text: 'Kasım '},
        {value: '12', text: 'Aralık '}
      ],
      series: [],
      chartOptions: {
        chart: {
          type: 'bar',
          height: 300,
          stacked: true,
        },
        plotOptions: {
          bar: {
            borderRadius: 5,
            columnWidth: '60%',
            dataLabels: {
              total: {
                enabled: true,
                offsetX: 5,
                style: {
                  fontSize: '13px',
                  fontWeight: 900
                }
              }
            }
          },
        },
        stroke: {
          width: 1,
          colors: ['#fff']
        },
        xaxis: {
          max: 10,
          categories: [],
        },
      },
    }
  }, mounted() {
    this.getDataFromApi()
  },
  methods: {
    getDataFromApi() {
      this.loading = true
      ApiService.setHeader()
      ApiService.post('api/Student/meeting-report', this.filters)
          .then(({data}) => {

            let personnelNames = [...new Set(data.map(x => x.PersonnelName))]
            let dates = [...new Set(data.map(x => x.Date))]
            personnelNames.forEach(p => {
              let personnelData = data.filter(x => x.PersonnelName == p)
              let personnelSeries = []
              dates.forEach(d => {
                let personnelDateData = personnelData.filter(x => x.Date == d)
                if (personnelDateData.length > 0)
                  personnelSeries.push(personnelDateData[0].MeetingCount)
                else
                  personnelSeries.push(0)
              })

              this.series.push({
                name: p,
                data: personnelSeries
              })
            })

            this.chartOptions.xaxis.categories = dates.map(x => {
              let date = moment(x)
              return `${this.localeMonths[date.month()].text} ${date.format('YYYY')}`
            })

            this.loading = false
          })
          .catch(({response}) => {
            ApiService.showError(response)
            this.loading = false
          })
    }
  }
}
</script>

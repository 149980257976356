<template>
  <div>
    <v-card-title class="pb-1">
      Ödev Performansı
<!--      <v-spacer></v-spacer>-->
<!--      <v-btn-toggle-->
<!--          v-model="isFirst"-->
<!--          mandatory-->
<!--          dense-->
<!--          color="primary"-->
<!--          @change="getDataFromApi"-->
<!--      >-->
<!--        <v-btn :value="false" small>-->
<!--          Son İşaretleme-->
<!--        </v-btn>-->
<!--        <v-btn :value="true" small>-->
<!--          İlk İşaretleme-->
<!--        </v-btn>-->
<!--      </v-btn-toggle>-->
    </v-card-title>
    <apex-chart
        v-if="!loading && (series[0].data.length > 0 || series[1].data.length > 0 || series[2].data.length > 0)"
        :options="chartOptions"
        :series="series"
        type="bar"
        height="400"
    ></apex-chart>
    <v-alert v-else type="info" text class="m-5">
      Seçilen tarihler aralığında işaretleme yapılan ödev bulunamadı.
    </v-alert>
    <v-card-actions v-if="series[0].data.length > 0 || series[1].data.length > 0 || series[2].data.length > 0" class="justify-center">
      <v-chip v-for="(item,i) in series" :key="item.name" class="mr-2" label :color="chartOptions.colors[i]" dark :small="$vuetify.breakpoint.mobile">
        {{ item.data.reduce((a, b) => a + b, 0) }} {{ item.name }}
      </v-chip>
      <v-chip label :small="$vuetify.breakpoint.mobile">
        İşaretleme Yapılan Soru {{ series.reduce((a, b) => a + b.data.reduce((c, d) => c + d, 0), 0) }}
      </v-chip>
    </v-card-actions>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service.js'

export default {
  props: ['filters'],
  data() {
    return {
      loading: false,
      isFirst: false,
      series: [{
        name: 'Doğru',
        data: []
      }, {
        name: 'Yanlış',
        data: []
      }, {
        name: 'Boş',
        data: []
      }],
      chartOptions: {
        chart: {
          type: 'bar',
          height: 400,
          stacked: true,
        },
        plotOptions: {
          bar: {
            borderRadius: 3,
            dataLabels: {
              total: {
                enabled: true,
                offsetX: 5,
                style: {
                  fontSize: '13px',
                  fontWeight: 900
                }
              }
            }
          },
        },
        stroke: {
          width: 1,
          colors: ['#fff']
        },
        xaxis: {
          categories: [],
          labels: {
            formatter: function (val) {
              return val
            }
          }
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val
            }
          }
        },
        fill: {
          opacity: 1
        },
        colors: ['#00E396', '#F54E60', '#FFA800',]
      },
    }
  },
  mounted() {
    this.getDataFromApi()
  },
  methods: {
    getDataFromApi() {
      this.loading = true

      // eslint-disable-next-line vue/no-mutating-props
      this.filters.IsFirst = this.isFirst
      ApiService.setHeader()
      ApiService.post('api/Student/homework-report', this.filters)
          .then(({data}) => {
            this.chartOptions.xaxis.categories = data.map(s => s.CourseName)

            this.series[0].data = data.map(s => s.CorrectCount)
            this.series[1].data = data.map(s => s.WrongCount)
            this.series[2].data = data.map(s => s.EmptyCount)

            this.loading = false
          })
          .catch(({response}) => {
            ApiService.showError(response)
            this.loading = false
          })
    }
  }
}
</script>

<template>
  <v-card flat>
    <v-col cols="12">
      <v-card-title class="justify-center text-break">
        Randevu Katılım 
      </v-card-title>
    </v-col>
    <v-col cols="12" class="d-flex justify-content-center">
      <apex-chart v-if="!loading && chartOptions.series.length > 0" width="320" :options="chartOptions" :series="chartOptions.series"></apex-chart>
      <v-alert v-else type="info" text class="m-5">
        Seçilen tarihler aralığında randevu bulunamadı.
      </v-alert>
    </v-col>
    <v-col v-if="!loading && chartOptions.series.length > 0" cols="12" class="p-0">
      <v-card-title class="justify-center text-center p-0 text-black-50" style="font-size: 14px">
        Toplam {{ chartOptions.series.reduce((a, b) => a + b, 0) }} adet randevu alınmıştır.
      </v-card-title>
    </v-col>
  </v-card>
</template>

<script>
import ApiService from '@/core/services/api.service.js'

export default {
  props: ['filters'],
  data() {
    return {
      item: {},
      loading: false,
      chartOptions: {
        series: [],
        chart: {type: 'pie',},
        plotOptions: {
          pie: {
            dataLabels: {
              offset: -10
            }
          }
        },
        labels: [],
        colors: [],
        legend: {
          show: true
        }
      },
    }
  },
  mounted() {
    this.getDataFromApi()
  },
  methods: {
    getDataFromApi() {
      this.loading = true
      ApiService.setHeader()
      ApiService.post('api/Student/appointment-report', this.filters)
          .then((data) => {
            this.item = data.data
            this.chartOptions.labels = this.item.map(s => s.CourseName)
            this.chartOptions.series = this.item.map(s => s.Count)

            this.loading = false
          })
          .catch(({response}) => {
            ApiService.showError(response)
            this.loading = false
          })
    }
  }
}
</script>

<template>
  <v-card>
    <v-card-title v-if="!$vuetify.breakpoint.mobile" class="pb-0">
      <v-toolbar flat dense>
        <c-date-picker v-model="filters.startDate" @input="refresh" label="Başlangıç Tarihi" hide-details></c-date-picker>
        <v-divider class="mx-2" inset vertical></v-divider>
        <c-date-picker v-model="filters.endDate" @input="refresh" label="Bitiş Tarihi" hide-details></c-date-picker>
        <v-spacer></v-spacer>
        <v-btn color="warning" text @click="clearFilters">SIFIRLA</v-btn>
      </v-toolbar>
    </v-card-title>
    <v-card v-else flat>
      <v-container>
        <v-col cols="12">
          <c-date-picker v-model="filters.startDate" @input="refresh" label="Başlangıç Tarihi" hide-details></c-date-picker>
        </v-col>
        <v-col cols="12">
          <c-date-picker v-model="filters.endDate" @input="refresh" label="Bitiş Tarihi" hide-details></c-date-picker>
        </v-col>
      </v-container>
    </v-card>
    <v-card-text class="pt-0">
      <v-alert v-if="filters.startDate == null" type="info" class="m-5">Raporları görüntülemek için tarih seçiniz.
      </v-alert>
      <div v-else>
        <student-exam-report :filters="filters" :key="compKey"></student-exam-report>
        <v-divider class="mx-8"></v-divider>
        <student-homework-bar-chart :filters="filters" :key="compKey"></student-homework-bar-chart>
        <v-divider class="mx-8"></v-divider>
       <v-row v-if="!$vuetify.breakpoint.mobile">
         <v-row>
           <v-col>
             <student-appointment-report :filters="filters" :key="compKey"></student-appointment-report>
             
           </v-col>
           <v-col>
             <student-homework-planning-report :filters="filters" :key="compKey"></student-homework-planning-report>
             
           </v-col>
           <v-col>
             <student-attendance-report :filters="filters" :key="compKey"></student-attendance-report>
           </v-col>
         </v-row>
       </v-row>
        <div v-else>
          <v-col>
            <student-appointment-report :filters="filters" :key="compKey"></student-appointment-report>
          </v-col>
          <v-col>
            <student-homework-planning-report :filters="filters" :key="compKey"></student-homework-planning-report>
          </v-col>
          <v-col>
            <student-attendance-report :filters="filters" :key="compKey"></student-attendance-report>
          </v-col>
        </div>
        <v-divider class="m-8"></v-divider>
        <student-meeting-report :filters="filters" :key="compKey"></student-meeting-report>
        <v-divider class="m-8"></v-divider>
        <student-stability-report :filters="filters" :key="compKey"></student-stability-report>
      </div>
    </v-card-text>
    <v-card-actions></v-card-actions>
  </v-card>
</template>

<script>
import StudentExamReport from "@/view/pages/student_general_report/StudentExamReport.vue";
import StudentHomeworkBarChart from "@/view/pages/student_general_report/StudentHomeworkBarChart";
import StudentAttendanceReport from "@/view/pages/student_general_report/StudentAttendanceReport.vue";
import StudentHomeworkPlanningReport from "@/view/pages/student_general_report/StudentHomeworkPlanningReport.vue";
import StudentAppointmentReport from "@/view/pages/student_general_report/StudentAppointmentReport.vue";
import StudentMeetingReport from "@/view/pages/student_general_report/StudentMeetingReport.vue";
import StudentStabilityReport from "@/view/pages/student_general_report/StudentStabilityReport.vue";

export default {
  data() {
    return {
      filters: {
        startDate: new Date(Date.now() - 60 * 24 * 60 * 60 * 1000),
        endDate: null
      },
      compKey: 0
    };
  },
  components: {
    StudentStabilityReport,
    StudentMeetingReport,
    StudentAppointmentReport,
    StudentHomeworkPlanningReport,
    StudentAttendanceReport,
    StudentHomeworkBarChart,
    StudentExamReport
  },
  created() {
    const filters = JSON.parse(localStorage.getItem('studentReportFilters'))
    if (filters)
      this.filters = filters
  },
  methods: {
    refresh() {
      this.compKey += 1
      localStorage.setItem('studentReportFilters', JSON.stringify(this.filters))
    },
    clearFilters() {
      this.filters = {
        startDate: new Date(Date.now() - 60 * 24 * 60 * 60 * 1000),
        endDate: null
      }
      this.refresh()
    }
  }
}
</script>

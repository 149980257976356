<template>
  <v-card flat>
    <v-col cols="12" class="pb-0"> 
      <v-card-title class="justify-center text-break pb-0">
        Ödev Planlama
      </v-card-title>
    </v-col>
    <v-col cols="12" class="py-0">
      <apex-chart v-if="!loading" type="radialBar" height="300" :options="homeworkOptions" :series="homeworkOptions.series"></apex-chart>
    </v-col>
    <v-col cols="12" class="p-0">
      <v-card-title class="p-0 justify-center text-center text-break text-black-50" style="font-size: 14px">
        {{ item.HomeworkCount }} ödevin {{ item.PlannedHomeworkCount }} tanesi için planlama yapılmış. <br>
        Planlanan ödevlerin {{ item.StudentPlannedHomeworkCount }} tanesini öğrenci kendi planlamış.
      </v-card-title>
    </v-col>
  </v-card>
</template>

<script>
import ApiService from '@/core/services/api.service.js'

export default {
  props: ['filters'],
  data() {
    return {
      item: {},
      loading: false,
      homeworkOptions: {
        series: [],
        chart: {
          type: 'radialBar',
          toolbar: {
            show: false
          }
        },
        plotOptions: {
          radialBar: {
            startAngle: -179,
            endAngle: 180,
            hollow: {
              margin: 0,
              size: '60%',
              background: '#fff',
              position: 'front',
              dropShadow: {
                enabled: true,
                top: 3,
                left: 0,
                blur: 4,
                opacity: 0.24
              }
            },
            track: {
              background: '#fff',
              strokeWidth: '67%',
              dropShadow: {
                enabled: true,
                top: -3,
                left: 0,
                blur: 4,
                opacity: 0.35
              }
            },
            dataLabels: {
              show: true,
              name: {
                offsetY: -10,
                show: false,
                color: '#888',
                fontSize: '15px'
              },
              value: {
                formatter: function (val) {
                  return '%' + parseInt(val);
                },
                color: '#555',
                fontSize: '36px',
                show: true,
              }
            }
          }
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            type: 'horizontal',
            shadeIntensity: 0.5,
            gradientToColors: ['#ffb822'],
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100]
          }
        },
        stroke: {
          lineCap: 'round'
        },
        labels: ['Yüzde'],
      },
    }
  },
  mounted() {
    this.getDataFromApi()
  },
  methods: {
    getDataFromApi() {
      this.loading = true
      ApiService.setHeader()
      ApiService.post('api/Student/homework-planning-report', this.filters)
          .then((data) => {
            this.item = data.data
            this.homeworkOptions.series = this.item.HomeworkCount > 0 ? [this.item.PlannedHomeworkCount / this.item.HomeworkCount * 100] : [0]
            this.loading = false
          })
          .catch(({response}) => {
            ApiService.showError(response)
            this.loading = false
          })
    }
  }
}
</script>

<template>
  <v-card flat>
    <v-col cols="12" class="pb-0">
      <v-card-title class="justify-center text-break">
        Yoklama
      </v-card-title>
    </v-col>
    <v-col cols="12">
      <apex-chart v-if="!loading && series.length > 0" type="donut" height="275" :options="chartOptions" :series="series"></apex-chart>
      <v-alert v-else type="info" text class="m-5">
        Seçilen tarihler aralığında devamsızlık bulunamadı.
      </v-alert>
    </v-col>
    <v-col v-if="!loading && series.length > 0" cols="12" class="p-0">
      <v-card-title class="justify-center text-center p-0 text-black-50" style="font-size: 14px">
        Toplam {{ series.reduce((a, b) => a + b, 0) }} ders saati devamsızlık yapılmıştır.
      </v-card-title>
    </v-col>
  </v-card>
</template>

<script>
import ApiService from '@/core/services/api.service.js'

export default {
  props: ['filters'],
  data() {
    return {
      item: {},
      loading: false,
      series: [],
      chartOptions: {
        chart: {
          height: 290,
          type: 'donut',
        },
        plotOptions: {},
        colors: ['#5867dd', '#0abb87', '#ffb822', '#fd397a'],
        labels: [],
        legend: {
          show: true,
          floating: true,
          fontSize: '14px',
          position: 'left',
          offsetY: -25,
          labels: {
            useSeriesColors: true,
          },
          formatter: function (seriesName, opts) {
            return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex]
          },
          itemMargin: {
            vertical: 3
          }
        },
      },
    }
  },
  mounted() {
    this.getDataFromApi()
  },
  methods: {
    getDataFromApi() {
      this.loading = true
      ApiService.setHeader()
      ApiService.post('api/Student/attendance-report', this.filters)
          .then((data) => {
            this.item = data.data
            this.chartOptions.labels = this.item.map(s => s.AttendanceStatus)
            this.series = this.item.map(s => s.Count)

            this.loading = false
          })
          .catch(({response}) => {
            ApiService.showError(response)
            this.loading = false
          })
    }
  }
}
</script>
